import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import CircularLoader from 'components/components/Loader';

// Lazy load app
const App = lazy(() => import('./App'));

// Load App after Google Maps scring is loaded
const loadApp = () => {
  ReactDOM.render(
    <Suspense fallback={<CircularLoader />}>
      <App />
    </Suspense>,
    document.getElementById('root'),
  );
};

loadApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
