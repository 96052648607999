import { CircularProgress } from '@material-ui/core';
import React from 'react';
import theme from 'utils/theme/theme';
import image from 'assets/logo.png';

const CircularLoader: React.FC<ComponentProps> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        width: '100vh',
        minWidth: '100%',
      }}
    >
      <div>
        <img width='150' height='75' src={image} alt='logo' />
      </div>
      <hr style={{ width: '200px', opacity: '0.2' }} />
      <div>
        <CircularProgress
          size={26}
          style={{
            color: theme.palette.primary.main,
          }}
        />
      </div>
    </div>
  );
};

interface ComponentProps {}

export default CircularLoader;
