import { createTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

//TODO: Create custom theme that will be used throughout the application
const theme = createTheme({
  palette: {
    primary: { main: orange[900], light: '#ffe5d6' },
    secondary: { main: orange[900] },
  },
  overrides: {
    MuiGrid: {
      container: {
        width: '100% !important',
      },
    },
  },
});

export default theme;
